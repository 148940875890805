.range-date-picker {
    border: 1px solid transparent;
    border-radius: 5px;
    width: fit-content;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    margin-right: 10px;
}

.rmdp-input:focus {
    width: 230px !important;
}

.rmdp-week-day {
    color: #102447 !important;
    cursor: default;
    font-size: 13px;
    font-weight: 500;
}

.rmdp-range {
    background-color: #102447 !important;
    box-shadow: 0 0 1px #8798ad !important;
    color: #fff;
}

.rmdp-day.rmdp-today span {
    background-color: #102447 !important;
    color: #fff;
}

.rmdp-day, .rmdp-week-day {
    color: #000;
    cursor: pointer;
    height: 34px;
    position: relative;
    width: 34px;
    border-radius: 2px !important;
}
.rmdp-arrow {
    border: solid #102447 !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block;
    height: 3px;
    margin-top: 5px;
    padding: 2px;
    width: 3px;
}

.rmdp-arrow-container:hover {
    background-color: transparent !important;
    box-shadow: 0 0 3px #8798ad;
}
