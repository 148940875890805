.ErrorBoundaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.ErrorBoundaryHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.ErrorBoundaryHeader svg {
  color: rgb(227, 31, 40);
  margin-right: 18px;
  font-size: 28px;
}

.ErrorBoundaryHeader p {
  margin-top: 10px;
  color: #0f1943;
  font-size: 1.25rem;
  padding-top: 0.20286em;
}

.ErrorBoundaryBody p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #6f758e;
  font-size: 0.875rem;
}

.ErrorBoundaryButton {
  padding: 8px 20px;
  background-color: #43425d;
  border: 0 none;
  cursor: pointer;
  font-size: medium;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 30px;
}

.ErrorBoundaryButton:hover {
  background-color: #656380;
}
