.container {
  margin: 0 !important;
  padding: 0 !important
}

.loginpage {
  width: calc(100vw - 3%);
  height: 100vh
}

.loginpage-left-container,
.loginpage-right-container {
  width: 100%;
  height: 100%
}

.loginpage-left-container>img {
  width: 100%;
  height: 100vh !important;
  position: relative
}

.loginpage-left-container-content>img {
  position: absolute;
  top: 0;
  width: 195px;
  height: 75px;
  margin: 20px;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 7px;
}

.loginpage-left-container-content>p {
  position: absolute;
  top: 60px;
  width: 50%;
  height: -webkit-fill-available;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
  display: block !important;
  padding-top: 11rem
}

.loginpage-left-container-content-image {
  top: 90px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  padding-top: 17rem;
  border: 1px solid transparent;
  transform: translate(39%)
}

.loginpage-left-container-content-images {
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-left: 1rem
}

.loginpage-right-container-form {
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 7% 4%;
  margin-top: 10%;
  width: 100%;
  background-color: #f0f0f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(10px, 40px);
}

.loginpage-form-header {
  border: 1px solid transparent;
  /* margin-bottom: 20px; */
  text-align: center
}

.loginpage-form-header img {
  width: 40%;
  height: 100%
}

.loginpage-form-header p {
  margin-top: 5%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0;
  color: #43425d;
  opacity: 1
}

.loginpage-form-header span,
.loginpage-rediredt-btn span {
  font-size: 1rem;
  font-weight: 400
}

.loginpage-form-header span {
  text-align: center;
  letter-spacing: 0;
  color: #4d4f5c;
  opacity: 1
}

.loginpage-rediredt-btn {
  background-color: #102447;
  border: 1px solid transparent;
  box-shadow: 0 3px 6px #00000029;
  color: #fff;
  width: 70%;
  margin: 3% 17%;
  padding: 2%;
  cursor: pointer;
  border-radius: 5px
}

.loginpage-rediredt-btn:hover {
  box-shadow: 0 0 0 3px #8e8caf
}

.loginpage-rediredt-btn img {
  width: 30px;
  height: 30px;
  margin-right: 10px
}

@media screen and (min-width:1536px) {
  .loginpage-left-container-content>img {
    width: 280px;
    height: 140px
  }

  .loginpage-left-container-content-image {
    padding-top: 23rem;
    transform: translate(82%)
  }

  .loginpage-left-container-content>p {
    padding-top: 17rem
  }
}

.loginpage-signin {
  border: 1px solid #f0f0f7;
  border-radius: 8px;
  background-color: #f0f0f7;
  padding: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end
}

.timer {
  border: 1px solid transparent;
  width: fit-content;
  border-radius: 5px;
}

.timer_span {
  border: 1px solid transperent;
  padding: 2px 4px;
  border-radius: 5px;
  margin-right: 3px;
  font-weight: 500;
}