.rm-table-pagination {
    padding: 10px;
    border-radius: 5px;
    display: flex;
    font-weight: 500;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
}

.rm-table-pagination-page-no {
    border: 1px solid transparent;
    background-color: rgb(212 232 248 / 37%);
    margin: 0 10px;
    padding: 2px 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.rm-table-pagination-page-no>span{
    border: 1px solid transparent;
    background-color: rgba(213, 214, 214, 0.473);
    border-radius: 5px;
    margin: 1px 0px 1px 4px;
    padding: 1px 5px;
}

.rm-table-pagination-page-no .current-page {
    min-width: 40px;
    max-width: 50px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 4px 8px;
    text-align: center;
    margin: 0px 10px;
    font-size: inherit;
    font-weight: 500;
}