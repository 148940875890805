.Extraction {
  width: 100%;
  height: 100vh;
  position: relative;
}

.Extraction-btn {
  position: relative;
}

.btn-form {
  border: 0;
  border-radius: 3px;
  width: 81px;
  height: 33px;
  color: #dddddd;
  margin-bottom: 8px;
  background-color: #21223e;
}

.btn-download {
  border: 0;
  border-radius: 3px;
  width: 125px;
  height: 25px;
  color: #dddddd;
  margin-bottom: 8px;
  font-size: 14px;
}

.btn-download:hover {
  box-shadow: 0 0 0 3px rgb(120, 150, 199);
}

.Extraction-tables {
  /* position: absolute; */
  position: relative;
  width: -webkit-fill-available;
  height: 88vh;
  /* padding: 5px 8px 0px 5px; */
  overflow: auto;
}

.Extraction-tables-header {
  font-size: 30px;
  font-weight: 700;
  color: #5a6b7d;
  margin-top: 10px;
  margin-bottom: 2px;
}

.Extraction-tables::-webkit-scrollbar {
  -webkit-appearance: scrollbar;
  height: 4px;
  width: 4px;
}

.Extraction-tables::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 3px;
}

.nav-link {
  font-size: 25px;
  font-weight: 500;
  color: #5a6b7d !important;
}

.nav-link.active {
  color: rgb(16, 36, 71) !important;
}

.nav-justified .nav-item .nav-link {
  width: 100% !important;
  height: 30px !important;
  padding: 0px !important;
  font-size: 18px !important;
}

.table {
  width: 100% !important;
  border-collapse: collapse;
  font-size: 12px;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  background-color: white;
  padding: 6px 7px;
}

.table,
.thead,
.tr {
  text-align: left;
  color: rgb(16, 36, 71);
}

.th {
  color: rgb(16, 36, 71);
  font-size: 12px !important;
  font-weight: 500;
}

.td {
  font-size: 11px !important;
}

.td,
.th {
  border: 2px solid #dddddd !important;
  border-radius: 4px;
  padding: 6px 7px;
}

.table .tbody .tr {
  border-bottom: 1px solid #dddddd;
}

.table .tbody .tr:nth-of-type(even) {
  background-color: rgba(243, 243, 243, 0.815);
}

.table1 {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  background-color: white;
}

.table1,
.thead1,
.tr1 {
  text-align: left;
  color: rgb(16, 36, 71);
}

.th1 {
  color: black;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.td1 {
  font-size: 10px;
  height: 20px !important;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.table1 .td1,
.table1 .th1 {
  border: 2px solid #dddddd;
  border-radius: 4px;
  padding: 3px 7px;
  min-width: 100px;
}

.table1 .tbody1 .tr1 {
  border-bottom: 1px solid #dddddd;
  position: relative;
}

.table1 .tbody1 .tr1:nth-of-type(even) {
  background-color: rgba(243, 243, 243, 0.815);
}

#bsTable .verify_data_header {
  position: sticky;
  left: 0px;
  background: #ccc;
  z-index: 100;
}

#bsTable .verify_data {
  position: absolute;
  left: 0px;
  z-index: 10;
  display: none;
  top: 50%;
  transform: translate(0%, -50%);
  padding: unset;
  border: unset;
  background: transparent;
}

#bsTable .tbody1 .tr1:hover .verify_data {
  display: block;
}

#bsTable .verify_data svg {
  margin: 0px 6px;
  font-size: 18px;
  cursor: pointer;
}

#bsTable .verify_data .approve {
  color: green;
}

.page_number_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_number_search .searchIcon {
  zoom: 1.5;
  position: relative;
  right: 10px;
  cursor: pointer;
}
thead{
  background-color:  rgb(195 195 195);
}

.Extraction .acc-doc-details .nav-item {
  width: 50%;
}

.Extraction .acc-doc-details .nav-item button {
  width: 100%;
  font-size: 14px;
}

.Extraction .acc-doc-details .nav-item button.active {
  font-weight: 600;
}

.Extraction .acc-doc-details-table {
  width: 100%;
  border: 1px solid #ccc;
}

.Extraction .acc-doc-details-table thead tr {
  background: #ccc;
}

.Extraction .acc-doc-details-table thead th, .Extraction .acc-doc-details-table tbody td {
  padding: 6px 10px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 50%;
  font-size: 13px;
}

#bsTable .column .add-column-icon {
  display: none;
}

#bsTable .column:hover .add-column-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0px;
  cursor: pointer;
  color: white;
  background: black;
  padding: 3px;
  font-size: 12px;
  border-radius: 3px;
}
