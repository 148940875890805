.pa_output_container {
    padding: 10px 10px 5px 10px;
    display: flex;
    gap: 8px;
}

.pa_left_container {
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    overflow: auto;

}

.pa_right_container {
    width: 50%;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
}

.pa_left_container,
.pa_right_container {
    border: 1px solid transparent;
    border-radius: 8px;
    height: 90vh;
    overflow: auto;
}

.pa_desc {
    border: 1px solid transparent;
    border-radius: 8px;
    margin: 5px;
    padding: 10px;
}

.pa_accordion {
    border: 1px solid transparent;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;

}

.pa_donut_chart {
    width: 100%;
    padding: 10px;
}

.pa_donut_chart>header,
.pa_right_container>header {
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    margin-bottom: 5px;
}