.analysis-main-wrap {
	padding: 10px;
	margin-top: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.time-series .TScharts {
	padding: 15px;
	border: 1px solid #dcdada;
	border-radius: 5px;
	width: 48%;
	margin-bottom: 20px;
}

.time-series .TScharts .charts-header {
	font-size: 14px;
	font-weight: 600;
}

.time-series .TScharts .tabs_container {
  width: 100%;
	margin: unset;
}

.detection .each-box {
	border-radius: 5px;
	box-shadow: 0px 0px 10px 0px #ccc;
	padding: 40px 10px 10px;
}

.detection .search {
	top: 20px;
	right: 20px;
	color: #4a4a4a;
	cursor: pointer;
}

.accounts-compare .graph-options button {
	font-size: 12px;
	font-weight: 500;
	min-width: 140px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.graph-options .account {
	font-size: 12px;
	font-weight: 500;
	min-width: 140px;
	background-color: rgb(14, 16, 70);
}

.graph-options .graph-column {
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	width: 48%;
	margin: 10px;
}