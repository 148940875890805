.RequestMonitoring-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RequestMonitoring-container-header {
    width: 95%;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-bottom: 8px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
}

.RequestMonitoring-card-container {
    width: 95%;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    padding-bottom: 20px;
}

.rm-fallback-ui {
    width: 95%;
    height: auto;
    margin: 5px 20px;
    padding: 60px;
    border: 1px solid #509AF8;
    background-color: #d7f1fda1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rm-fallback-ui>span {
    margin-top: 20px;
}