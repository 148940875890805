.gen-ai-history,
.genai-history-table-header,
.genai-history-table-body {
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    width: 100%
}

.gen-ai-history {
    border: 1px solid transparent;
    border-radius: 5px;
    height: 100vh;
    padding: 5px;
    margin-top: -10px
}

.genai-history-table-header,
.genai-history-table-body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: .3rem;
    padding: 3px 6px 0 0;
    margin-bottom: 5px;
    height: 40px;
    font-size: .8rem !important;
    font-weight: 500;
    color: #102447
}
.genai-history-table-body{
    font-weight: 400 !important;
}

.genai-history-table-header-sno,
.genai-processing-card-sno {
    width: 5%
}

.genai-processing-card-filename,
.genai-history-table-header-filename {
    width: 20%;
}

.genai-history-table-header-dateAndTime,
.genai-processing-card-date,
.genai-processing-card-llm-type,
.genai-history-table-header-llm_type {
    width: 20%
}

.genai-history-table-header-status,
.genai-processing-card-status-msg {
    width: 10%
}


.genai-history-table-header-download,
.genai-processing-card-download-btn {
    width: 5%
}