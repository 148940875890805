.genai_accordion {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  width: 99%;
  height: auto;
  margin-bottom: 1rem;
  margin-left: 2px;
}

.accordion_question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
  word-wrap: break-word;
  max-height: 90px;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.toggled {
  background-color: rgb(231, 241, 255);
  color: rgb(12, 125, 235);
}

.accordion_answer {
  border-top: 0.08rem solid rgb(184, 211, 238);
  padding: 10px;
  display: flex;
  width: 100%;
  height: auto;
  max-height: 150px;
  overflow: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}