.dashboard-header {
    border: 1px solid #eee;
    box-shadow: 1px 5px 5px #eee;
    border-radius: 5px;
    background-color: #fff;
    width: auto;
    height: 3rem;
    margin: 0.02rem 0.5rem 0 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;
}

.userDashboard-grid-cell {
    position: relative;
    border: 1px solid #eee;
    box-shadow: 1px 5px 5px #eee;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px 0;
}

.userDashboard-grid-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.userDashboard-grid-header-container>span {
    border: 1px solid transparent;
    border-radius: 4px;
    margin-right: 10px;
}

.userDashboard-grid-heading {
    margin: 0 12px;
}

.userDashboard-grid-content {
    padding: 1rem;
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
}

.userDashboard-grid-heading:hover {
    cursor: move;
}

.react-grid-layout {
    position: relative;
    overflow: hidden;
    width: -webkit-fill-available !important;
}

.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}

.react-grid-item img {
    pointer-events: none;
    user-select: none;
}

.react-grid-item.cssTransforms {
    transition-property: transform;
}

.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.dropping {
    visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
    background: greenyellow;
    opacity: 0.3;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item>.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
}

.react-grid-item>.react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 10px;
    height: 10px;
    border-right: 2px solid rgb(16, 36, 71);
    border-bottom: 2px solid rgb(16, 36, 71);
}

.react-resizable-hide>.react-resizable-handle {
    display: none;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w,
.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n,
.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

