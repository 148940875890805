.Readaction-output-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Redaction-select-fields,
.Redacted-Fields,
.Redaction-plaintext {
  border: 2px double transparent;
  width: calc(100% - 3%);
  text-align: left;
  overflow: auto;
}

.Redaction-select-fields-table,
.Redacted-fields-table,
.Redaction-plaintext-table {
  width: 100%;
}

.Redaction-select-fields-table tbody {
  border: 1px solid transparent;
  border-radius: 4px !important;
}

.Redaction-select-fields-table-td {
  padding: 5px 0px 5px 15px;
}

.nav-justified .nav-item .nav-link {
  font-size: 16px !important;
}

.Redaction-plaintext {
  border: 2px solid rgba(128, 128, 128, 0.123);
  border-radius: 4px;
  padding: 5px;
}

.Redaction-plaintext>span {
  word-wrap: break-word;
  line-height: 25px;
  font-size: 0.8125rem !important;
}

.divider {
  border-bottom: 2px solid rgb(16, 36, 71);
  border-radius: 5px;
}

.Redaction-pdfViewer-controlpannel .form-check-label {
  overflow-wrap: break-word !important;
}

.Redaction_table_switch_checkfields {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  font-size: 0.8125rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
}

.Redaction_table_switch_checkfields_maincontainer {
  padding: 10px;
  border-radius: 5px;
  width: 99%;
  height: 70%;
  overflow: auto;
  margin-left: 2px;
  margin-top: -15px;
}

.Redaction_switch_checkfields_tooltip {
  position: absolute;
  z-index: 1000;
  left: 5rem;
  width: auto;
  height: auto;
  padding: 4px;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  border-radius: 5px;
  background-color: aliceblue;
}

.Redaction_switch_checkfields_tooltip>span {
  font-size: 12px;
}