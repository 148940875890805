.table-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 8px;
}

.table-buttons-download-options,
.table-buttons-download-options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.table-buttons-download-options-container {
    width: fit-content;
}

.table-buttons-download-options-icon {
    color: #fff;
    width: auto;
    height: 25px;
    font-size: 0.875rem;
    line-height: 1.5;
    border: transparent;
    border-radius: 0.2rem;
    padding: 2px 4px;
    cursor: pointer;
}

.table-buttons-download-options-icon>img {
    width: 18px;
    height: 18px;
    color: #fff;
    margin: 1px 1px;
}

.bank-stt-table-options {
    display: flex;
    align-items: center;
}

.bank-stt-table-options svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
    padding: 0px 6px;
}