.page_selection_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
}

.page_selection_body_container {
    width: 100%;
    height: 78vh;
    display: flex;
    justify-content: flex-start;
}

.page_selection_body_select_pdf_page {
    padding: 10px;
    width: 25%;
    overflow: hidden;
    border-right: 1px solid #ccc;
}

.page_selection_body_select_pdf_page:hover {
    overflow: scroll;
}

.page_selection_body_select_pdf_page,
.react-pdf__Document {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;

}

.select_pdf_page_container {
    border-radius: 8px;
    padding: 10px;
    margin: 7px 0px;
    cursor: pointer;
    position: relative;
    width: 48%;
}

.select_pdf_page_container:hover {
    border-color: rgba(16, 36, 71, 0.664) !important;
}

.select_pdf_page_no {
    position: absolute;
    color: rgb(3, 14, 32);
    z-index: 10;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #cccccc;
    width: 20px;
    height: 20px;
    padding: 1px 2px;
}

.page_selection_body_preview_page {
    padding: 0px 10px 10px 10px;
    width: 75%;
}

.page_selection_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 8px 20px;
    margin-right: 20px;
}

.custom_selection_input_container {
    width: 100%;
    height: 15%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px !important;
}

.custom_selection_input_header {
    font-weight: 500;
}

.custom_selection_input>input {
    height: 30px !important;
}