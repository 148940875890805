.user_managment {
  border: 1px solid transparent;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: 0px 0px 0px 4px;
}

.user_managment_header {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.user_managment_export_btn {
  margin-right: 10px;
}

.user_managment_back_btn {
  margin-left: 10px;
}

.user_management_left_side .user_managment_header .cbtn:hover {
  background-color: rgb(46, 58, 77);
}
.user_managment .tab-title {
  color: #102447;
  text-align: center;

  font-size: 22px;
  font-weight: 700;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;

  margin-bottom: 5px;
  padding: 5px;
  margin-left: 0px;
  border-bottom: none;
}

.user_management_side {
  display: grid;
  grid-template-columns: 70% 30%; /* Left column takes remaining space, right column fixed at 200px */
  padding-top: 15px;
}
.user_management_left_side {
  margin-left: 10px;
}
.user_management_right_side {
  margin-left: 20px;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  padding: 15px;
}

.user_management_right_side > .Search button {
  color: #fff;
  background-color: rgb(16, 36, 71);

  border: none;
}

.user_management_right_side > .Search button:hover {
  background-color: rgb(46, 58, 77) !important ;
}

.user_management_right_side .Search input {
  font-size: 13px;
}

.user_management_right_side > h3 {
  color: #102447;
  font-size: 14px;
  font-weight: 700;
}

.user_management_right_side .accordion-header button {
  font-size: 14px;
  font-weight: 500;
}

/* checkbox css */
.userManagementAccordion .userManagement-custom-checkbox {
  position: relative;
  padding-left: 30px !important;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  margin-bottom: 5px !important;
  font-weight: 500;
}

.userManagement-custom-checkbox input {
  position: absolute;
  opacity: 0;

  height: 0;
  width: 0;
}

.userManagement-custom-checkbox .form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #282828;
  background-color: #fff;
}

.userManagement-custom-checkbox input:checked ~ .form-check-label::after {
  content: "✓";
  position: absolute;
  left: 3px;
  top: -4px;
  width: 10px;
  height: 10px;
  color: #28a745;
  font-size: 18px;
  font-weight: 900;
}
.userManagement-custom-checkbox.addgreen .form-check-label::before {
  border: 2px solid #28a745;
}
