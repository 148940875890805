.pnf_container>img {
  position: absolute;
  top: 60px;
  left: 51px;
  width: 182px;
  height: 64px;
  padding: 0;
  border: 1px solid transparent;
}

.pagenotfound {
  border: 1px solid transparent;
  background-color: #f8f8f8a1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 88vh;
  margin: 10px;
}

.pagenotfound-404 {
  font-size: 60px;
  font-weight: 700;
  color: rgb(16, 36, 71);
}

.pagenotfound-msg {
  font-size: 20px;
  font-weight: 500;
  font-stretch: expanded;
  color: rgb(16, 36, 71);
}

.pagenotfound-pnf {
  font-size: 30px;
  font-weight: 600;
  font-stretch: expanded;
  color: rgb(16, 36, 71);
}

.pagenotfound-redirect {
  padding: 8px 20px;
  background-color: rgb(16, 36, 71);
  border: 0 none;
  cursor: pointer;
  font-size: medium;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 30px;
}

.pagenotfound-redirect:hover {
  color: #ffffff;
  background-color: rgb(16, 36, 71) !important;
  box-shadow: 0 0 0 3px rgb(79 89 106);
}