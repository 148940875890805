.error-logs-header,
.error-logs-body {
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.error-logs-header {
    font-weight: 500;
}

.error-logs-header_service,
.error-logs-header_form_type,
.error-logs-body_service,
.error-logs-body_form_type {
    width: 7%;
}

.error-logs-header_filename,
.error-logs-header_llm_type,
.error-logs-body_filename,
.error-logs-body_llm_type {
    width: 10%;
}

.error-logs-header_file_upload,
.error-logs-header_request_id,
.error-logs-body_file_upload,
.error-logs-body_request_id {
    width: 20%;
}

.error-logs-header_phase_name,
.error-logs-body_phase_name {
    width: 10%;
}