.item-label {
  margin-left: 8px;
}

.checkbox {
  padding: 10px;
}

.Redaction-Check-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.Redaction-Check-box label {
  padding: 0px 0px 0px 8px;
  font-size: 12px;
}

.Redaction-Check-input {
  width: 11px;
  height: 12px;
  background-color: rgb(0, 92, 200);
  outline-color: rgb(0, 92, 200);
}
.equal-label-width > span {
  width: 110px !important; /* Set a fixed width for the label */
  text-align: right; /* Optional: Align text to the right */
  background: rgb(224, 224, 224);
}


