.rent-roll-container {
    display: flex
}

.rent-roll-form,
.rent-roll-history {
    height: 84vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: -12px 5px 5px
}

.rent-roll-content,
.rent-roll-form,
.rent-roll-history {
    padding: 10px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    border-radius: .5rem;
    background-color: #fff !important
}

.rent-roll-options {
    padding: 10px;
    height: 100%;
    margin-left: 15px
}

.rent-roll-llm-option {
    display: flex;
    flex-direction: column
}

.rent-roll-llm-options {
    border: 2px dashed transparent;
    border-radius: .5rem;
    padding: 1rem;
    margin: .5rem;
    height: 100%
}

.rent-roll-llm-desc {
    font-size: smaller;
    margin-top: 4px;
    margin-bottom: 10px
}

.rent-roll-content {
    margin: 5px
}

.cre-processing-card {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 6px 6px 0;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    border-radius: .3rem;
    background-color: #fff !important;
    margin-bottom: 5px;
    width: 100%;
    height: 40px;
    font-size: .8rem;
    color: #102447
}

.cre-history-table-header,
.cre-pdf-modal-details {
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    border-radius: .3rem;
    margin-bottom: 5px;
    font-size: .8rem !important;
    font-weight: 500;
    color: #102447;
    display: flex
}

.cre-processing-card-status {
    display: flex;
    flex-direction: row;
    padding: 4px
}

.cre-history-table-header {
    justify-content: space-evenly;
    align-items: center;
    padding: 3px 6px 0 0;
    width: 100%;
    height: 40px
}

.cre-history-table-header-sno,
.cre-processing-card-sno {
    width: 4%
}

.cre-history-table-header-dateAndTime,
.cre-history-table-header-filename,
.cre-history-table-header-status,
.cre-processing-card-date,
.cre-processing-card-filename,
.cre-processing-card-status-msg {
    width: 15%
}

.cre-history-table-header-llm_type,
.cre-processing-card-llm-type {
    width: 8%
}

.cre-history-table-header-download,
.cre-history-table-header-preview,
.cre-processing-card-download-btn,
.cre-processing-card-preview-btn {
    width: 5%
}

.cre-history-pdf-viewer {
    width: 100%;
    height: 100%
}

#example-modal-sizes-title-lg {
    width: 100%
}

.cre-pdf-modal-filename {
    padding-left: 10px
}

.cre-pdf-modal-details {
    justify-content: space-between;
    align-items: center;
    padding: 3px 6px 0 0;
    width: 100%;
    height: 40px
}

.cre-pdf-modal-header {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 0 25px 0 10px
}

.cre-pdf-modal-options {
    display: flex;
    justify-content: space-between;
    width: 20%
}