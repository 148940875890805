.topbar {
  border: 0px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
  background-color: #fff;
  top: 0px;
  position: fixed;
  z-index: 1000;
}

.topbar-element {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.topbar-fabars button {
  width: 40px;
  height: 30px;
  border: 0px;
  background-color: inherit;
  position: relative;
  left: -26px;
}

.topbar-fabars button svg {
  width: 18px;
  height: 18px;
  color: rgb(16, 36, 71);
  background-color: #25274a;
  border-radius: 20px;
  box-shadow: 0 0 5px 0px rgb(142, 156, 180);
  padding: 4px;
}
.topbar-fabars button svg path {
  color: white;
}

.breadcrum span {
  margin-left: 0px;
  /* background-color: red; */
}

.topbar-fabars button svg:hover {
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgb(120, 150, 199);
}

.topbar-breadcrum {
  margin-top: 6px;
}

.topbar-dropdown {
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.topbar-dropdown-svg svg {
  margin-right: 25px;
  color: #a1a1b3;
  cursor: pointer;
}

.topbar-dropdown-svg svg:hover {
  color: #43425d;
}

.p-avatar-circle {
  border-radius: 50%;
  margin-right: 22px !important;
}

.avatar-circle {
  background-color: rgb(16, 36, 71);
  color: #ffffff;
  margin-left: 0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

@media screen and (min-width: 1536px) {
  .topbar {
    height: 60px;
  }

  .notification {
    top: 65px;
    right: 120px;
  }
}

@media screen and (max-width: 992px) {
  .topbar-fabars,
  .avatar {
    display: none;
  }
}

.notification {
  position: absolute;
  border: 1px solid transparent;
  border-radius: 5px;
  width: 450px;
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  right: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  top: calc(100% + 2px);
  left: 76%;
  transform: translateX(-50%);
}

.toggels {
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 5px;
  width: 160px;
  height: auto;
  overflow: auto;
  right: 250px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  top: calc(100% + 2px);
  left: 86%;
  transform: translateX(-50%);
}

.toggels-links {
  text-decoration: none;
  cursor: pointer;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  color: #545457;
}

.toggels-links > SVG {
  margin-right: 8px;
}

.notification-header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 5px 15px 5px 15px;
  width: 450px;
  background-color: #fff;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}

.notification-header > span > svg {
  width: 16px;
  height: 16px;
}

.notification-header > span > svg:hover {
  background-color: #a1a1b377;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  color: rgb(34, 30, 30);
}

.notification-body {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 35px 10px 0px 10px;
}

.notification-alert {
  width: 7px;
  height: 7px;
  border: 1px solid rgb(241, 7, 7);
  position: absolute;
  border-radius: 50%;
  background-color: red;
}

.notification-info {
  width: auto;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: #d7f1fd;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #509af8;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 1px;
  color: black;
}

.notification-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.notification-off {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: #d7f1fd;
  border-radius: 4px;
  margin-bottom: 5px;
}

.bell {
  color: rgb(16, 36, 71);
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}
