.RequestMonitoringModal {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.RequestMonitoringModal-header {
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    padding: 5px;
    border-radius: 8px;
}

.RequestMonitoringModal-body {
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 8px;
    height: 75vh;
    overflow: scroll;
}

.string-value {
    color: black !important;
}

.object-key {
    color: red !important;
}

.data-type-label {
    display: none !important;
}

.err-logs-json-viewer {
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}