.confirmation-dialog {
    position: fixed;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 365px;
    left: 50%;
    top: 15%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.confirmation-dialog-text {
    display: flex;
    justify-content: space-evenly;
}

.confirmation-dialog-buttons {
    display: flex;
    justify-content: center;
}