.tabs-container {
    border-radius: 8px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    margin: 0 10px;
    width: calc(100% - 20px);
    height:auto
}

.client-onboarding-container {
    border: 1px solid transperent;
    width: 96%
}

.client-onboarding-container>form {
    border: 1px solid transperent;
    border-radius: 5px;
    padding: 0 15px;
    width: 100%;
    margin: 10px
}

.input-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px
}

.sunmit-btn-cont {
    display: flex;
    justify-content: flex-end
}

.checklist-container {
    border: 2px solid rgba(16, 36, 71, .329);
    border-radius: 5px;
    padding: 3px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    width: 98%;
    color: rgba(16, 36, 71, .863);

}

.checklist-options {
    width: 48%
}

.checklist-options-input {
    display: flex;
    height: 30px;
}

.checklist-options-input-label {
    cursor: pointer;
    margin-left: 8px;
    margin-top: 4px
}

.checklist-options-values {
    display: flex;
    flex-direction: column;
    height: 30px
}

.checklist-container:hover {
    outline: 0;
    border-color: #007bff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}