* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.control-panel {
  border-radius: 3px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 25px;
  margin-bottom: 8px;
  background-color: rgb(16, 36, 71);
  color: rgb(255, 255, 255);
}

.page-control,
.print-control,
.download-control,
.zoom-control .page-rotate {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
  font-size: 13px;
}

.aleft,
.zoomminus {
  margin-right: 15px;
}

.aright,
.zoomplus {
  margin-left: 15px;
}

.page-rotate {
  margin-left: -70px;
}

.pageinput {
  margin-left: 9px;
  margin-right: 9px;
  border-radius: 4px;
  border: 2px solid transparent;
  height: 15px;
  width: 45px;
  padding-left: 6px;
}
