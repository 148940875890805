.billing-matrics {
    width: 97%;
    height: auto;
    border: 1px solid transparent;
    border-radius: 5px;
    margin: auto 10px;
}

.billing-matrics-header-pannel {
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    width: 99%;
    height: 40px;
    border: 1px solid transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 5px;
    margin: 5px 10px;
}

.billing-matrics-body-pannel {
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    width: 99%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 5px;
    margin: auto 10px;
}

.billing_tremap_cont {
    width: 48%;
}

.billing_heatmap_cont {
    width: 99%;
}

.billing_tremap_cont,
.billing_heatmap_cont {
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
}

.rmdp-input {
    border: 1px solid transparent !important;
}

.rmdp-input:focus {
    border: 1px solid transparent !important;
    box-shadow: 0 0 2px transparent !important;
    outline: none !important;
}