.layout-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
}

.layout-sidebar {
  position: relative;
  width: 100%;
  height: 100%;
}

.layout-mainbar {
  position: absolute;
  width: 100%;
  height: 100% !important;
}

.layout-card {
  width: auto;
  /* height: 100% !important; */
  height: auto;
  border-radius: 4px;
  /* padding: 5% 0%; */
  padding: 4.5% 0% 0% 0%;
  overflow: scroll;
}

@media screen and (min-width: 1536px) {
  .layout-card {
    padding: 5.3% 0% 0% 0%;
  }
}

@media screen and (max-width: 992px) {
  .layout-card {
    margin-top: 2%;
  }
}
