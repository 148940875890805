.rm-table-header {
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    font-weight: 500;
    justify-content: flex-start;
    align-items: center;
    height: 40px
}

.rm-table-header-form-type,
.rm-table-header-status {
    width: 10%
}

.rm-table-header-service {
    width: 15%
}

.rm-table-header-sno {
    width: 5%
}

.rm-table-header-file-upload-time {
    width: 20%
}

.rm-table-header-req-id {
    width: 30%
}

.rm-table-header-toggle {
    border: 1px solid transparent;
    border-radius: 5px;
    width: 30px
}

.rm-table-header-toggle>svg {
    margin-left: 8px
}

.rm-table-header-toggle:hover {
    background-color: rgb(16, 36, 71);
    color: #fff;
}

.rm-table-header-toggle>svg:hover {
    color: #fff
}