.qa_component_accordion {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 3%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  width: auto;
  height: auto;
}

.qa_component_accordion_question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10px;
}
