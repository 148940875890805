@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

p {
  margin: 0;
}

hr {
  margin-bottom: 0.3rem !important;
  margin-top: 0.3rem !important;
}

.cardheading {
  margin-bottom: 20px;
  color: #43425d;
}

::-webkit-scrollbar {
  -webkit-appearance: scrollbar;
  height: 8px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.nav-link .active {
  font-size: 15px !important;
}

.react-pdf__message,
.react-pdf__message--error {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  color: rgb(16, 36, 71);
}

.carousel-indicators {
  display: none !important;
}

.ImageControllPannel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  height: 25px;
  background-color: rgb(16, 36, 71);
  margin-bottom: 8px;
  color: rgb(255, 255, 255);
}

.ImageControllPannel_Zoom_btn {
  width: 25%;
}

.ImageControllPannel_Reset_btn {
  width: 60%;
}

.ImageControllPannel_Zoom_btn,
.ImageControllPannel_Reset_btn {
  border: 0;
  background-color: inherit !important;
  color: #dddddd;
  font-size: 13px;
  margin-left: 5px;
  margin-right: 5px;
}

.tab-component {
  border: 1px solid transperent;
  border-radius: 1rem;
  background-color: #ffffff;
  width: 100%;
  height: 97vh;
}

.table_container {
  width: 100%;
  overflow: scroll;
}

.heading {
  font-size: 1rem !important;
  font-weight: 500;
}

.para {
  font-size: 0.8125rem !important;
}

.carousel_conatiner {
  width: 100%;
  height: 85vh;
}

.carousel_conatiner>img {
  width: 100%;
  height: 45vh;
  margin-bottom: 5px;
  object-fit: cover;
}

.carousel_conatiner_title {
  color: #43425d;
  font-weight: 500;
  margin-bottom: 10px !important;
}

.anychart-credits-text,
.anychart-credits-logo {
  display: none;
}

#legend li span {
  width: 36px;
  height: 12px;
  display: inline-block;
  margin: 0 5px 8px 0;
  vertical-align: -9.4px;
}

#legend ul {
  list-style: none;
  font: normal 12px Verdana;
  white-space: nowrap;
}

:root {
  --toastify-toast-width: 380px !important;
}

.Toastify__toast {
  border-radius: 8px !important;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #141414 !important;
  --bs-backdrop-opacity: 0.9 !important;
}