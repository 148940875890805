:root{
    color:#82cdff,
    #049bff,
    #2ec4b6,
    #ffa0b4,
    #e27396,
    #00a5cf,
    #C34A36,
    #ffb703,
    #ee6c4d,
    #9b5de5,
    #072929,
    #e6d9ff,
    #d3f5f5,
    #adc178,
    #ff4069,
    #84a59d,
    #ffe9d2,
    #F9F871,
    #76c893,
    #ffff3f,
}
.tabs_container {
    border: 1px solid transparent;
    width: 48%;
    padding: 2px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    margin: 5px;
}