.container-loader {
  position: relative;
  display: grid;
  padding: 25%;
  border: 1px solid transparent;
  border-radius: 5px;
  opacity: 1;
}

.container-loader1 {
  position: relative;
  width: 95%;
  height: 45vh;
  display: grid;
  place-items: center;
}

.container-loader2 {
  position: relative;
  width: 100%;
  height: 80vh;
  display: grid;
  place-items: center;
}

.container-loader2 p {
  font-size: 20px;
  font-weight: 500;
}

.loading {
  display: flex;
  position: absolute;
  transform: scale(2);
  top: 50%;
  left: 50%;
}

.loading .ball {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 0.5rem;
  transition: transform 0.3s infinite;
}

.ball+.ball {
  margin-left: 0.4rem;
}

.loading .ball.one {
  animation: loadingBall 0.5s infinite alternate;
  background: #ef371f;
}

.loading .ball.two {
  animation: loadingBall2 0.5s infinite 0.2s alternate;
  background: #625e5e;
}

.loading .ball.three {
  animation: loadingBall3 0.5s infinite 0.35s alternate;
  background: #697f18;
}

.loading .ball.four {
  animation: loadingBall4 0.5s infinite 0.5s alternate;
  background: #125f74;
}

@keyframes loadingBall {
  0% {
    transform: translateY(-10px) scale(1.2);
    background: #ff5929;
    box-shadow: 0 0 2px #ff5929aa;
  }

  100% {
    transform: translateY(5px);
  }
}

@keyframes loadingBall2 {
  0% {
    transform: translateY(-10px) scale(1.2);
    background: #fc0651;
    box-shadow: 0 0 2px #fc0651aa;
  }

  100% {
    transform: translateY(5px);
  }
}

@keyframes loadingBall3 {
  0% {
    transform: translateY(-10px) scale(1.2);
    background: #6cb944;
    box-shadow: 0 0 2px #6cb944aa;
  }

  100% {
    transform: translateY(5px);
  }
}

@keyframes loadingBall4 {
  0% {
    transform: translateY(-10px) scale(1.2);
    background: #2de2fa;
    box-shadow: 0 0 2px #2de2faaa;
  }

  100% {
    transform: translateY(5px);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 110%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.247); */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.progress-container {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(255, 255, 255, 0.041); */
  opacity: 10;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px; */
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 60px;
}

.progress-container>img {
  width: 115px;
  height: 40px;
}

.progress-message {
  display: flex;
  justify-content: center;
  color: rgb(8, 18, 35);
  /* text-align: center; */
}

.progress {
  background-color: rgb(16, 36, 71);
  border-radius: 2px;
  position: relative;
  margin: 15px 0;
  height: 35px;
  width: 400px;
  transition: width 0.6s ease;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 0, #0000 50%, #ffffff26 0, #ffffff26 75%, #0000 0, #0000);
  background-size: auto;
}

.progress-done {
  background-color: rgb(16, 36, 71);
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}