/* Container styling */
.dashboardContainer {
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Grid layout for the dashboard */
.dashboardGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

/* Left panel (controls) */
.controlsPanel {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdada;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px #ccc;
  height: 500px;
  overflow: auto;
}

/* Right panel (chart display) */
.chartPanel {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #dcdada;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px #ccc;
  width: 100%;
}

/* Chart type controls */
.chartTypeControls {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

/* Variables section styling */
.variablesSection {
  margin-top: 20px;
}

.variableLabel {
  font-weight: 500;
  margin-bottom: 5px;
}

.variableSelect {
  width: 100%;
}

/* Add Variable button styling */
.addVariableBtn {
  width: 100%;
  margin-top: 15px;
  padding: 8px;
  /* font-weight: bold; */
  background-color: rgb(16, 36, 71);
  /* border-color: none; */
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.addVariableBtn:hover {
  background-color: rgb(24, 45, 81);
  border-color: rgb(16, 36, 71);
}

@media (max-width: 992px) {
  .dashboardGrid {
    grid-template-columns: 1fr;
  }

  .controlsPanel {
    margin-bottom: 2rem;
  }
}
