.pa_form_input_cont {
    display: flex;
    flex-direction: column;
    position: static;
    width: auto;
    min-width: 48%;
    height: 20px;
    margin: 5px 0px 50px 5px;
}

.pa_form_input_cont label.pa_form_input_label {
    font-size: .70rem;
    color: rgba(16, 36, 71, .863);
    font-weight: 600;
    position: relative;
    top: .5rem;
    margin: 0 0 0 7px;
    padding: 0 3px;
    background: #f0f0f7;
    width: fit-content;
    height: fit-content
}

.pa_form_input_cont input[type=number].pa_form_input,
.pa_form_input_cont input[type=text].pa_form_input,
.pa_form_input_cont input[type=email].pa_form_input,
.pa_form_input_cont input[type=password].pa_form_input {
    padding: 11px 10px;
    font-size: .75rem;
    border: 2px solid rgba(16, 36, 71, 0.329);
    border-radius: 5px;
    background: #f0f0f7
}

.pa_form_input_cont input[type=number].pa_form_input:focus,
.pa_form_input_cont input[type=text].pa_form_input:focus,
.pa_form_input_cont input[type=email].pa_form_input:focus,
.pa_form_input_cont input[type=password].pa_form_input:focus {
    outline: 0;
    border-color: #007bff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}