.rm-card {
    width: 100px;
    /* width: auto; */
    height: auto;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 0 5px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    /* display: flex; */
    text-align: center;
    /* position: absolute; */
}

.rm-card-header {
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
    padding: 5px;
}

.rm-card-header-title {
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rm-card-body {
    border: 1px solid rgba(100, 99, 99, 0.24);
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
}

.rm-card-footer {
    border: 1px solid transparent;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.rm-desc-card {
    margin-top: 3px;
    z-index: 10;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    padding: 10px;
    width: 160px;
    min-width: 160px;
    max-width: 230px;
    height: auto;
    top: calc(100% + 2px);
    left: 68%;
    transform: translateX(-50%);

}

.rm-desc-card-title {
    font-weight: 500;
}