/* FilterBar.css */
.filter-bar {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  margin-top: -10px;
  margin-bottom: 6px;
  padding: 0 8px 0 0;
}
.filter-bar-one-side {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.search-form {
  width: 100%;
}
.filter-bar .cbtn,
.search-form input[type="text"] {
  height: 2.19rem !important;
  padding: 0px 13px;
}
.filter-bar .para button {
  height: 23.6px !important;
}
.search-form input[type="text"]:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(169, 169, 169, 0.5);
}
.filter-bar .cbtn {
  font-size: 14px;
  border: none;
  border-radius: 0px 5px 5px 0px;
}
.cbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-bar .date-filters {
  display: flex;
  align-items: center;
  width: fit-content !important;
  box-shadow: unset !important;
}

.search-form input[type="text"] {
  font-size: 13px;
}

.rmdp-container .ep-arrow + div {
  left: -37px !important;
}
.rmdp-header-plugin {
  width: 70px !important;
}

.range-date-picker .rmdp-input:focus {
  width: auto !important;
}

.date-filters .range-date-picker {
  border: 1px solid rgba(169, 169, 169, 0.5) !important;
  border-radius: 5px 0px 0px 5px !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
  margin-right: 0px !important;
  height: 35px !important;
}

.date-filters .analyze-link,
.date-filters .analyze-link:hover {
  text-decoration: none;
  color: white;
}
