.rm-table-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    padding: 8px;
    font-weight: 400;
    margin-bottom: 5px;
    border-radius: 5px;
}

.rm-table-cards:hover {
    background-color: rgb(230, 230, 231);
    cursor: pointer;
}

.rm-table-card {
    border-radius: 5px;
    width: 100%;
    display: flex;
}

.rm-table-cards.open {
    border-color: rgba(18, 155, 196, 0.596);
    background-color: #fff;
}

.rm-sub-card-container {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
}

.RequestMonitoring-card-container .pagination {
    box-shadow: 0px 0px 10px 0px #999797;
    padding: 6px 6px 6px 15px;
    margin-top: 20px;
    border-radius: 4px;
    font-size: 12px;
    position: fixed;
    bottom: 47px;
    width: auto;
    right: 48px;
    background: white;
    z-index: 10;
}

.RequestMonitoring-card-container .num-of-list {
    margin-right: 10px;
}

.RequestMonitoring-card-container .num-of-list .form-select {
    width: auto;
    font-size: inherit;
    margin-left: 8px;
}

.RequestMonitoring-card-container .num-of-list .form-select:focus {
    outline: none;
}