.drag-and-drop {
  border: 2px dashed rgb(16, 36, 71);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100% !important;
  min-height: 18rem !important; */
}

.drag-and-drop-btn {
  margin-left: 5px;
  border: 0px;
  background-color: transparent;
  text-decoration: underline;
  color: blue;
}

.drag-and-drop-btn:hover {
  color: #e16b8f;
}

.drag-and-drop-title {
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
}

.drag-and-drop-title p {
  /* margin-top: 3%; */
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  color: #43425d;
  opacity: 1;
}

.drag-and-drop-file-msg {
  font-size: medium;
  font-weight: 500;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drag-and-drop-file-msg-img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.drag-and-drop-file-msg-img img {
  /* width: 112px; */
  width: 75px;
  height: 112px;
  background: transparent !important;
}

.drag-and-drop-file-msg-img p {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.8px;
  color: rgb(16, 36, 71);
  text-transform: uppercase;
  opacity: 1;
  height: 60px;
  overflow: scroll;
}

.drag-and-drop-file-msg-btn {
  width: 25px;
  height: 25px;
  border: 1px solid transparent;
  border-radius: 50%;
  margin-bottom: 50px;
  margin-left: 20px;
  background-color: rgb(204, 79, 79);
  color: white;
  font-size: 15px;
  font-weight: 400;
}

.drag-and-drop-file-msg-btn:hover {
  box-shadow: 0 0 0 3px #f0b1c4 !important;
}

.drag-and-drop-img img {
  background: transparent !important;
  margin-left: 15px;
  width: 108px;
  height: 100px;
}

@media screen and (max-width: 992px) {
  .drag-and-drop {
    height: 10rem !important;
  }
}