.profile-user-page .data-user li a,
.profile-user-page .data-user li strong{
    display: block
}

.profile-user-page .data-user li a:hover span{
    color: #3498db
}

.content-profile-page {
    margin: 1em auto;
    width: 48.23em;
}

.card {
    background: #fff;
    border-radius: .3rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    border: .1em solid rgba(0, 0, 0, .2);
    margin-bottom: 1em
}

.profile-user-page .img-user-profile {
    margin: 0 auto;
    text-align: center
}

.profile-user-page .img-user-profile .profile-bgHome {
    border-bottom: .2em solid #f5f5f5;
    width: 48.23em;
    height: 16em
}

.profile-user-page .img-user-profile .avatar {
    margin: -10em auto 0;
    background: #fff;
    width: 7em;
    height: 7em;
    padding: .25em;
    border-radius: .4em;
    box-shadow: 0 0 .1em rgba(0, 0, 0, .35)
}

.profile-user-page .description-profile,
.profile-user-page .user-profile-data {
    text-align: center;
    padding: 0 1.5em
}

.profile-user-page .user-profile-data h1 {
    font-family: Lato, sans-serif;
    margin-top: .35em;
    color: #292f33;
    margin-bottom: 0
}

.profile-user-page .user-profile-data p {
    font-family: Lato, sans-serif;
    color: #8899a6;
    font-size: 1.1em;
    margin-top: 0;
    margin-bottom: .5em
}

.profile-user-page .description-profile {
    color: #75787b;
    font-size: .98em
}

.profile-user-page .data-user {
    font-family: Quicksand, sans-serif;
    margin-bottom: 0;
    cursor: pointer;
    padding: 0;
    list-style: none;
    display: table;
    width: 100.15%
}

.profile-user-page .data-user li {
    margin: 0;
    padding: 0;
    width: 33.33334%;
    display: table-cell;
    text-align: center;
    border-left: .1em solid transparent
}

.profile-user-page .data-user li:first-child {
    border-left: 0
}

.profile-user-page .data-user li:first-child a {
    border-bottom-left-radius: .3rem
}

.profile-user-page .data-user li:last-child a {
    border-bottom-right-radius: .3rem
}

.profile-user-page .data-user li a {
    background-color: #f7f7f7;
    border-top: 1px solid rgba(242, 242, 242, .5);
    border-bottom: .2em solid #f7f7f7;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .4), 0 1px 1px rgba(255, 255, 255, .4);
    padding: .93em 0;
    color: #46494c
}

.profile-user-page .data-user li a span,
.profile-user-page .data-user li a strong {
    font-weight: 600;
    line-height: 1
}

.profile-user-page .data-user li a strong {
    font-size: 2em
}

.profile-user-page .data-user li a span {
    color: #717a7e
}

.profile-user-page .data-user li a:hover {
    background: rgba(0, 0, 0, .05);
    border-bottom: .2em solid #3498db;
    color: #3498db
}