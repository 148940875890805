.fileupload-component {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  height: max-content;
}

.fileupload-component-switch {
  padding: 2% 0%;
  border-bottom: 1px solid rgb(230, 227, 227);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline !important;
}

.fileupload-component-radio {
  padding: 4% 4%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 18%;
}

.fileupload-component-upload {
  border: none;
  margin: 0% 4%;
  height: 40% !important;
}

.fileupload-component-upload p {
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1.8px;
  color: rgb(16, 36, 71);
  text-transform: uppercase;
  opacity: 1;
}

.form-control {
  width: 56% !important;
  height: 45px !important;
}

.form-switch.form-check-reverse .form-check-input {
  margin-top: 7px;
  margin-right: -1.5em !important;
}

.form-check-label {
  margin-right: 18px !important;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em !important;
  margin-bottom: 2px !important;
}

.fileupload-component-btn {
  margin: 7% 4% 3% 79%;
}

.fileupload-component-content {
  padding: 1%;
  margin-left: 3% !important;
  width: auto;
  height: 100% !important;
  background-color: #ffffff !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  opacity: 1;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 0.5rem;
}

.fileupload-component-content > img,
.fileupload-component-content-data > img {
  width: 100% !important;
  height: auto;
}

.fileupload-component form {
  width: 100%;
  height: 100%;
  border: 1px solid transparent !important;
  border-radius: 0.5rem;
  background-color: #ffffff !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
}

.tab-title {
  overflow: hidden;
}

.tab-title svg {
  margin-right: 3%;
}

@media screen and (max-width: 992px) {
  .drag-and-drop-img img {
    width: 70px;
    height: 70px;
    margin-left: 30px;
  }
}

.radio-wrapper {
  border: 2px solid transparent;
  padding-left: 3px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
  width: fit-content;
}

.radio-wrapper:hover {
  transform: translateY(-4px);
  border: 2px solid #1291eb49;
  background-color: #1291eb32;
  color: rgb(16, 36, 71);
  font-weight: 400;
}
