.genai_redaction_output {
    width: 100%;
    height: 84vh;
}

.genai_redaction_output_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 30px;
}

.genai_redaction_output_header h6 {
    width: 80%;
}


.genai_redaction_output_body {
    padding: 10px;
    width: 100%;
    height: 90%;
    overflow: auto;
}

#redactedText {
    height: 68vh !important;
    border: 1px solid transparent;
    border-radius: 5px;
}

.genai_redaction_textarea {
    width: 100%;
    height: 73vh;
    resize: none;
    padding: 10px;
    border: none;
    line-height: 30px;
}
