.organization-select-box .pa-form-input-cont {
 float: right;
  display: flex;
  flex-direction: column;
  position: static;
  width: 25%;
  margin: 0px 10px 50px 5px;
}

.organization-select-box {
  display: block;
}

.organization-select-box .itemselect{
  border: 2px solid rgba(158, 172, 197, 0.329);
}

.organization-select-box .pa-form-input-label {
  font-size: 13px !important;
}
