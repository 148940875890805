.genai_summary_conatiner {
  width: 100%;
  height: 97vh;
  padding: 13px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
}

.genai_summary_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6%;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid gray;
}

.genai_summary_header h6 {
  text-transform: capitalize;
}

.genai_summary_preview {
  width: 100%;
  height: 92%;
  overflow: auto;
}

.genai_summary_preview p {
  padding-top: 0.3rem;
}

.btn {
  background-color: rgb(16, 36, 71) !important;
  border-color: rgb(16, 36, 71) !important;
}

.genai_summary_dropdown {
  height: auto;
  max-height: 50vh;
}

.genai_summary_dropdown_pagewise {
  height: auto;
  max-height: 30vh;
  overflow: scroll;
}