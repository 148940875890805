.sidebar {
  background-color: rgb(16, 36, 71);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  width: 18%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  top: 0px;
  z-index: -1;
  transition: 0.5s;
}

.sidebar-core {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 130px;
}

.sidebar-core .sidebar-header {
  height: 15%;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-core .sidebar-header img {
  width: 100%;
  height: 90%;
  padding: 0;
  margin-bottom: 10px;
  margin-left: -2%;
}

.sidebar-core .sidebar-body {
  width: 100%;
  height: 80%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: auto;
}

.sidebar-core .sidebar-body::-webkit-scrollbar {
  -webkit-appearance: scrollbar;
  height: 4px;
  width: 6px;
}

.sidebar-core .sidebar-body::-webkit-scrollbar-thumb {
  background-color: rgba(243, 241, 241, 0.5);
  border-radius: 3px;
}

.sidebar-core .sidebar-body div {
  position: relative;
  margin-bottom: 10%;
}

.sidebar-core .sidebar-body div a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 8px;
  color: white;
  /* width: 109%; */
  width: 95%;
  text-decoration: none;
  margin-left: 5px;
}
.sidebar-body .notactive :hover {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 30px;
}
.sidebar-body .notactive:hover .icon,
.sidebar-body .notactive:hover .items {
  background: none;
}

.sidebar-core .sidebar-body div a .icon {
  margin-right: 15px;
  margin-left: 10px;
}

.sidebar-core .sidebar-body div.active a {
  background: #ffffff;
  color: rgb(16, 36, 71);
  /* border-top-left-radius: 25px;
  border-bottom-left-radius: 25px; */
  /* box-shadow: 0 0 0 2px rgb(120, 150, 199); */ /* same as like hover button*/
  /* box-shadow: 0 2px 6px 1px rgb(142, 188, 231); */ /*outside blur border */
  border-radius: 25px;
  width: 95%;
}

.sidebar-core .sidebar-body div.active .top_curve,
.sidebar-core .sidebar-body div.active .bottom_curve {
  position: absolute;
  left: 0 !important;
  width: 110%;
  height: 47%;
  background: #f0f0f7;
}

.sidebar-core .sidebar-body div.active .top_curve {
  top: -20px;
  left: 2px;
}

.sidebar-core .sidebar-body div.active .bottom_curve {
  bottom: -20px;
  left: 2px;
}

.sidebar-core .sidebar-body div.active .top_curve:before,
.sidebar-core .sidebar-body div.active .bottom_curve:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 98.5%;
  height: 100%;
  background-color: rgb(16, 36, 71);
}

.sidebar-core .sidebar-body div.active .top_curve:before {
  border-bottom-right-radius: 25px;
}

.sidebar-core .sidebar-body div.active .bottom_curve:before {
  border-top-right-radius: 25px;
}

.item {
  font-size: 1rem;
}

.items {
  text-align: left;
}

.sidebar-footer {
  padding: 10px;
  color: white;
  width: 100%;
  margin-top: 10%;
  font-size: 0.8rem;
  height: 20%;
}

@media only screen and (max-width: 992px) {
  .icon {
    display: none;
  }

  .items {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }

  .sidebar-core .sidebar-body div a {
    width: auto;
    padding: 2.9%;
  }

  .sidebar-footer {
    font-size: 10px;
  }
}
