.excel_viewer_controls {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 26px;
    margin-bottom: 5px;
    border: 1px solid transparent;
    padding: 1px 4px;
    border-radius: 4px;
    background-color: #102447
}

.no-of-sheets {
    color: #fff;
    margin-right: 20px
}

.left-arrows,
.right-arrows {
    border: 1px solid transparent;
    border-radius: 4px;
    width: 25px;
    height: 20px;
    margin-top: 5px
}

.left-arrows>svg,
.right-arrows>svg {
    margin-bottom: 3px !important
}

.left-arrows:hover,
.right-arrows:hover {
    border: 1px solid transparent;
    background-color: #608bd6c2;
    border-radius: 4px;
    width: 25px;
    height: 20px;
    color: #fff;
    margin-top: 5px
}

.left-arrows {
    margin-left: 30px;
    margin-right: 10px
}

.right-arrows {
    margin-left: 10px
}

.Spreadsheet {
    margin-bottom: 20px !important;
    font-size: 12px;
    font-weight: 500
}

.Spreadsheet__table>colgroup>col {
    width: 130px !important
}

.Spreadsheet__table>colgroup>col:first-child {
    width: 5px !important
}

.Spreadsheet__cell {
    width: 100px
}

.Spreadsheet__header {
    min-width: 10px !important;
    width: 10px !important;
}

.excel_viewer {
    overflow: auto;
}