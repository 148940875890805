.genai_switches_main_container {
  margin: 7px 0 0 0;
  width: 100%;
  height: 100vh;
}

.redaction-switches {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  padding: 10px 10px 0px 10px;
  margin-bottom: 10px;
}

.redaction-switches h6 {
  margin: 0px;
}

.switch-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  padding: 5px 5px 0px 5px;
  margin-left: 12px;
}

.genai_switch_checkfields_maincontainer {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 70%;
  overflow: auto;
}

.genai_switch_checkfields {
  border: 1px solid transparent;
  padding: 4px;
  margin-bottom: 3px;
  font-size: 0.8125rem;
}

.list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.list-item {
  list-style-type: disc;
  font-size: 0.8125rem;
}

.genai_switch_label {
  margin-right: 10px;
}

.info_svg {
  color: black;
}

.genai_switch_checkfields_tooltip {
  position: absolute;
  z-index: 1000;
  left: 5rem;
  width: auto;
  height: auto;
  padding: .5rem;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  border-radius: 5px;
  background-color: aliceblue;
}