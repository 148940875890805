.rm-sub-card-header,
.rm-sub-card-body {
  border: 1px solid transparent;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.rm-sub-card-header {
  box-shadow: rgba(0, 0, 0, 0.24) 0 0 2px;
  font-weight: 500 !important;
}
.rm-sub-card-form-type {
  width: 10%;
}

.rm-sub-card-service {
  width: 15%;
}

.rm-sub-card-sno {
  width: 5%;
}

.rm-sub-card-file-upload-time {
  width: 20%;
}
