.PdfViewer {
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  height: 92vh;
  box-shadow: rgb(0 0 0 / 24%) 0px 0px 2px;
  background-color: white;
}

.PdfViewer::-webkit-scrollbar {
  -webkit-appearance: scrollbar;
  height: 4px;
  width: 4px;
}

.PdfViewer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.PdfViewer:hover {
  overflow: scroll;
}

.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  display: none !important;
}

.annotationLayer {
  padding: 0px;
  width: 0px !important;
  height: 0px !important;
  display: none !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto !important;
  display: block !important;
  user-select: none !important;
  width: 100% !important;
  height: 100% !important;
}

.transform-component-module_content__FBWxo {
  width: 100% !important;
}

.container-loader3 {
  position: relative;
  display: grid;
  place-items: center;
  margin-top: 250px;
  border: 1px solid transparent;
  border-radius: 10px;
  opacity: 1;
}

.Redacted-PdfViewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Redacted-PdfViewer canvas {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.Redacted-PdfViewer-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid black;
  background-color: rgb(16, 36, 71);
  border-radius: 4px;
  padding: 4px;
  margin-top: 2px;
  margin-bottom: 7px;
  width: 40%;
  height: 25px;
}

.Redacted-pdfviewer-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.Redacted-pdfviewer-header p {
  margin-top: 3px;
  margin-left: 2%;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.Redacted-pdfviewer-header > p > span {
  display: inline-block;
  height: 10px !important;
  width: 10px !important;
  border-radius: 50%;
  background-color: rgb(16, 36, 71);
  margin-bottom: 1%;
}

.PdfViewer-btn {
  border: 1px solid transparent;
  border-radius: 4px;
  width: 20%;
  height: 20px;
  background-color: transparent;
}

.PdfViewer-btn:hover {
  background: rgba(104, 101, 101, 0.25);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.PdfViewer-btn svg {
  color: white;
  margin-bottom: 2px;
}

.Redacted-PdfViewer-pages {
  font-size: 14px !important;
  color: white;
  text-align: center;
  margin: 0px 5px;
}

.Redacted-Pdf {
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 100vh;
  box-shadow: rgb(0 0 0 / 24%) 0px 0px 2px;
  overflow: hidden;
}

.cellHightlight {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.highlightArrow {
  color: black;
  position: relative;
  left: -12px;
  font-size: 10px;
}