/* .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(20, 20, 20, 0.5);
    transition: all 0.5s ease-in-out;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
} */

.loader {
    width: 100%;
    text-align: center;
    display: block;
    z-index: 99999;
}

.loader__bounce {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #faf7f7;
    border-radius: 30px;
    animation: loader-bounce 1.4s infinite ease-out both;
}

.loader__bounce.first {
    animation-delay: -0.32s;
}

.loader__bounce.second {
    animation-delay: -0.16s;
}

@keyframes loader-bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}