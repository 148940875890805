.billing-info-display,
.billing-info-update-options {
    width: auto;
    height: 80vh;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px
}

.billing-info-display-heading,
.billing-info-update-options-heading {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.billing-info-display-values,
.billing-info-update-options-inputs {
    border: 1px solid transparent;
    padding: 15px;
    width: auto
}

.billing-info-update-options {
    padding: 10px
}

.billing-info-display-values>div,
.billing-info-update-options-inputs>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px
}

.billing-info-display-values-title>svg {
    margin-right: 10px;
    font-size: 12px
}

.billing-info-display-values-cost,.billing-info-update-options-input {
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: rgba(184, 184, 170, .527);
    padding: 5px 15px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #343438
}

.billing-info-display-values-cost>svg {
    margin-right: 2px;
    font-weight: 500;
    font-size: 16px
}

.billing-info-display-controls,
.billing-info-update-options-controls {
    padding: 10px;
    display: flex;
    justify-content: flex-end
}

.billing-info-display-controls>button>svg,
.billing-info-update-options-controls>button>svg {
    margin-right: 5px;
    font-weight: 500;
    font-size: 12px
}

.billing-info-update-options-inputs {
    display: flex;
    flex-direction: column
}

.toggel-download-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 160px;
    height: auto;
    overflow: auto;
    right: 0;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    margin: 10px;
    z-index: 10
}

.toggel-download-options-val {
    text-decoration: none;
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    color: #545457
}

.toggel-download-options-val:hover {
    color: #509af8
}

/* .billing-info-update-options-input {
    border-radius: 5px;
    background-color: rgba(184, 184, 170, .527);
    padding: 5px 15px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #343438
} */

.billing-info-update-options-input>svg {
    margin-right: 5px;
}

.billing-info-update-options-input>input {
    border: 1px solid transparent;
    border-radius: 5px;
    padding-left: 5px;
    width: 100px;
}