.footer-container {
    border: 1px solid transparent;
    width: 100%;
    height: 40px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 33px;
    bottom: 0;
}

.footer-container span {
    word-spacing: 5px;
    font-weight: 500;
}

.footer-links>a {
    text-decoration: none;
    padding: 0px 15px;
    color: black;
    font-weight: 500;
}

.footer-links>a:hover {
    text-decoration: underline;
}