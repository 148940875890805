.rm-sub-card-header1 {
    border: 1px solid transparent;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    font-weight: 500;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    font-weight: 400 !important;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px;
    font-weight: 500 !important;
}

.rm-sub-card-sno1 {
    width: 5%;
}

.rm-sub-card-service1 {
    width: 10%;
}

.rm-sub-card-form-type1 {
    width: 10%
}

.rm-sub-card-file-upload-time1 {
    width: 20%
}

.rm-table-header-req-id1 {
    width: 25%
}

.rm-table-header-get-logs1 {
    width: 15%
}

.rm-table-header-status1 {
    width: 10%
}