.genai_tabs {
  width: 100%;
  height: 100vh;
}

.genai_tab-pane {
  margin: 0px;
}

.genai_tab_title {
  border: 1px solid transparent;
  padding: 4px;
  margin-bottom: 0 !important;
  color: rgb(16, 36, 71);
}

.genai_header_buttons {
  width: 100%;
  display: flex;
  /* display: contents; */
  align-items: center;
  margin-bottom: 8px;
  height: 5%;
}

.genai_heaber_tools {
  display: flex;
  justify-content: flex-end;
  margin-right: 37px;
  width: 16%;
}

.genai_header_backbutton {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.genai_header_title {
  width: 60%;
  margin-left: 8px;
}

.genai_header_nextbutton {
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-left: 1%;
}

.genai_statement_template_container {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  height: auto;
  max-height: 250px;
  margin-bottom: 10px;
}

.genai_questions_tab {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.genai_redaction_output_tab {
  padding: 0px;
}

.genai_statement_tab {
  width: 100%;
  height: 100vh;
}

.genai_statement_description_container {
  height: auto;
  max-height: 250px;
  padding: 10px;
  border: 1px solid transperent;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
}

.custom-pannelg {
  width: 100%;
  height: 100vh;
}

.custom-panel {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
  /* overflow-y: scroll !important; */
}

.custom-resize-handle {
  width: 5px;
  background-color: #77777754;
  cursor: e-resize;
  transition: background-color 0.2s;
}

.custom-resize-handle:hover {
  background-color: #888;
}

.nav-justified .nav-item .nav-link {
  font-size: 16px !important;
  width: 100% !important;
  height: 30px !important;
  padding: 0px !important;
}

.nav-link.active {
  padding: 0.4rem !important;
  color: rgb(16, 36, 71) !important;
}

.iq_svg {
  color: black !important;
}

.genai_aioutput {
  width: 100%;
  height: 90vh;
  overflow: auto;
}

.genai_questions_container {
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}

.genai_llm_types {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
