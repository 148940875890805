.pa-container {
    display: flex
}

.pa-form {
    height: 88vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5px;
    border-radius: 8px;
    margin-top: 7px
}

.pa-options,
.pa-tabs-container {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, .24) 0 0 2px
}

.pa-options {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 8px;
    height: 45px
}

.pa-tabs-container {
    margin: 0 10px;
    padding: 10px;
    width: calc(100% - 20px)
}

.pa-uploadfile-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end
}

.pa-form-input-cont {
    display: flex;
    flex-direction: column;
    position: static;
    width: 48%;
    min-width: 30%;
    height: 20px;
    margin: 5px 5px 30px 5px;
}

.pa-form-input-cont label.pa-form-input-label {
    font-size: .70rem;
    color: rgba(16, 36, 71, .863);
    font-weight: 600;
    position: relative;
    top: .5rem;
    margin: 0 0 0 7px;
    padding: 0 3px;
    background: #fff;
    width: fit-content;
    height: fit-content
}

.pa-form-input-cont input[type=number].pa-form-input,
.pa-form-input-cont input[type=text].pa-form-input {
    padding: 11px 10px;
    font-size: .75rem;
    border: 2px solid rgba(16, 36, 71, 0.329);
    border-radius: 5px;
    background: #fff
}

.pa-form-input-cont input[type=number].pa-form-input:focus,
.pa-form-input-cont input[type=text].pa-form-input:focus {
    outline: 0;
    border-color: #007bff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.itemselect {
    padding: 11px 10px;
    font-size: .75em;
    border-radius: 4px;
    border: 2px solid rgba(16, 36, 71, 0.329);
    width: 100%;
    outline: 0;
    color: rgba(0, 0, 0, .555)
}

.paform-sunmit-btn {
    display: flex;
    justify-content: flex-end;
    margin: 15px
}