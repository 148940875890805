.chart-fallback-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #509AF8;
    border-radius: 4px;
    padding: 15%;
    background-color: #d7f1fda1;
    width: auto;
    height: auto;
}

.chart-fallback-container-content {
    display: flex;
    margin-top: 15px;
    text-align: center;
}

.chart-fallback-container-content>p{
    margin-left: 10px;
}
