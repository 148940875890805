.custom-dropdown {
    position: relative;
    display: inline-block;
}

.cdropdown-header {
    cursor: pointer;
    padding: 2px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 130px;
    height: 25px;
    background-color: rgb(16, 36, 71);
    color: #dddddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cdropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    margin-top: 3px;
    background-color: #fff;
    border-radius: 5px;
    width: 130px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.cdropdown-option {
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
}

.cdropdown-option:hover {
    color: blue;
}

.cdropdown-option:last-child {
    border-bottom: none;
}