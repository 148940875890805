#dropdown-basic {
  background-color: transparent !important;
  border: none !important;
  color: #21223e !important;
}

.c-dropdown {
  position: relative;
}

.c-dropdown button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  background-color: inherit;
  border: 1px solid transparent;
  margin-right: 20px;
  text-transform: capitalize;
}

.c-dropdown button svg {
  text-align: center;
  margin-left: 5px;
  margin-bottom: 3px;
}

.c-dropdown-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 130px;
  background-color: white;
  border-radius: 5px;
  top: 45px;
  right: 45px;
  position: absolute;
  border: 1px solid transparent;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.c-dropdown-item {
  text-align: left;
  margin: 12px 2px 0px 2px;
  width: 130px;
  padding: 1px;
  border: 1px solid transparent;
}

.c-dropdown-item a {
  margin-left: 15px;
}

.c-dropdown-item a:hover {
  color: aliceblue;
}

.c-dropdown-item:hover {
  border: 1px solid rgb(16, 36, 71);
  background-color: #9fa1e09c;
  border-radius: 3px;
  color: white !important;
}

.c-dropdown-item-link {
  text-decoration: none;
}

.c-dropdown-item-link svg {
  color: #21223e !important;
}

.c-dropdown-item-links {
  font-size: 14px;
  margin-left: 8px;
  color: #21223e;
}
