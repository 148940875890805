/* Fade-in effect for nodes */
.node {
    opacity: 0;
    animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  /* Flow animation for links */
  .link {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: flow 2s ease forwards;
  }
  
  @keyframes flow {
    to {
      stroke-dashoffset: 0;
    }
  }
  #svg-sankey text {
    font-stretch: condensed !important;
  }

