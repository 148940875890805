.RequestMonitoring-flowchart {
    width: 100%;
    border: 1px solid aliceblue;
    background-color: rgba(177, 183, 184, 0.226);
    /* background-color: rgb(167 208 215 / 23%); */
    border-radius: 5px;
    padding: 0px 20px 0px 50px;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.RequestMonitoring-card-wrapper {
    margin: 10px 70px 20px 5px;
}

