.admin_settings {
 
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: 15px 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.admin_settings .admin_setting_container1 h6 {
  margin-bottom: 1rem;
}
.admin_setting1{
margin-bottom:2% ;
}
.admin_setting1,
.admin_setting2,
.admin_setting_container2 {
 
  border-radius: 2%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  padding: 16px 22px 22px 22px;
}
.check-status {
  margin: 1%;
  padding: 0.8%;
}

.admin_setting_container1,
.admin_setting_container2 {
  width: 50%;
  margin: 1%;
}

.admin_setting_container1 h6 {
  color: #102447;
  font-weight: 700;
}

.adminPage .tab-title {
  color: #102447;
  text-align: center;

  font-size: 22px;
  font-weight: 700;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;

  margin-left: 4px;
  padding: 5px;
  border-bottom: none;
}
.tab-title svg {
  margin-right: 0.8%;
}
hr{
  margin-bottom: 15px !important;
}
.check-status .form-switch{
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 0rem !important;
}