.outputscreen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.outputscreen-section1 {
  display: flex;
  position: relative;
  flex-direction: column;
  /* width: 49%; */
  width: 99%;
  height: 107vh;
  border-radius: 4px;
  padding: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 1%;
  cursor: auto;
}

.outputscreen-controllpannel,
.outputscreen-pdfviewer {
  width: 100%;
}

.outputscreen-pdfviewer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.Outputscreen-imgViewer {
  width: 100%;
  border-radius: 5px;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Outputscreen-imgViewer img {
  width: 100%;
  border-radius: 5px !important;
}

.outputscreen-section2 {
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  width: 99%;
  height: 107vh;
  border-radius: 4px;
  padding: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 0px 1% 0px 0px;
  cursor: auto;
}

.backendresponse-fail-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #f8f8f8a1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  width: auto;
  height: 88vh;
  margin: 10px;
}

.backendresponse-fail-msg img {
  width: 90px;
  height: 90px;
}

.backendresponse-fail-msg div {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  font-stretch: expanded;
  color: #43425d;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.backendresponse-fail-msg a:hover {
  color: #ffffff;
  background-color: #656380;
}

.backendresponse-redirect {
  padding: 8px 20px;
  background-color: rgb(16, 36, 71);
  border: 0 none;
  cursor: pointer;
  font-size: medium;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 30px;
}

.backendresponse-redirect:hover {
  background-color: rgb(16, 36, 71) !important;
  box-shadow: 0 0 0 3px rgb(79 89 106);
}

.output-table .extraction-table-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  overflow: hidden;
}

.output-table .extraction-table-tab .nav-item {
  display: flex;
  align-items: center;
}

.output-table .extraction-table-tab .nav-item .nav-link {
  height: unset !important;
  padding: 6px 30px !important;
}

.output-table .extraction-table-tab .nav-item .nav-link span {
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.output-table .extraction-table-tab::-webkit-scrollbar {
  -webkit-appearance: scrollbar;
  height: 4px;
  width: 4px;
}

.output-table .extraction-table-tab::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.output-table .extraction-table-tab:hover {
  overflow-x: scroll;
}

.output-table > ul {
  margin-bottom: 0px !important;
}

.Redaction-pdfViewer-component {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.rentroll-pdf-viewer {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 60%;
  border-radius: 10px;
  padding: 0.5rem;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
}

.rentroll-pdf-viewer-controll {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
}
.output-table .nav .active {
  display: flex;
  background-color: rgb(16, 36, 71);
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  overflow: hidden;
  color: white !important;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.output-table .extraction-table-tab .nav-item .active span {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
}

.nav-item .active {
  background-color: rgb(16, 36, 71) !important;
  color: white !important;
}
