.rent-roll-questions {
    border: 1px solid transparent;
    border-radius: .5rem;
}
.rent-roll-question{
    border: 1px solid transparent;
    border-radius: .3rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
    margin: 1%;
}