.analysis {
  padding: 0px 10px;
}

.analysis .nav .nav-item .active {
  font-weight: 600;
}

.analysis .analysis-btn .btn {
  font-size: 12px;
  font-weight: 500;
  min-width: 80px;
  background-color: #102447c7 !important;
  margin-right: 10px;
}

.analysis .analysis-btn .active {
  font-weight: 700;
  background-color: #102447 !important;
}

.analysis .dates-analysis .rmdp-container .rmdp-input {
  border: 1px solid #ccc !important;
  font-size: 12px;
  padding: 6px 12px;
  height: unset;
  margin-right: 10px;
}

.analysis .dates-analysis .filterBtn {
  font-size: 12px;
  white-space: nowrap;
}

.analysis .dates-analysis .filterBtn svg {
  margin-right: 8px;
  font-size: 12px;
}

.analysis .dates-analysis .rmdp-container {
  width: 160px;
  margin-right: 10px;
}

.analysis .dates-analysis .rmdp-container input {
  width: 100%;
  font-size: 12px;
}

.analysis .analysis-filter {
  border: 1px solid #ccc;
  padding: 6px 10px;
  border-radius: 5px;
}

.analysis .analysis-filter .dropdown-toggle {
  font-size: 12px;
}

.analysis .analysis-filter .dropdown-toggle::after {
  margin-left: 20px;
}