.extraction_report {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 100;
    box-shadow: 0px 0px 10px 0px #ccc;
    border-radius: 5px;
    top: 5px;
    left: 4px;
}

.extraction_report_wrap {
    position: relative;
    padding: 20px 20px 10px;
}

.extraction_report_closeBtn {
    position: absolute;
    right: 16px;
    top: 12px;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.extraction_report_header {
    border-bottom: 1px solid #ccc;
}

.extraction_report_header_name {
    font-size: 18px;
    margin-bottom: 10px;
}

.extraction_report_details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 18px 0px 15px;
    border-bottom: 1px solid #ccc;
}

.extraction_report_details_section {
    width: 48%;
    margin-bottom: 20px;
}

.extraction_report_details_section .data {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.3px;
}

.extraction_report_details_section .data span {
    font-weight: 500;
}

.unstructured_data {
    margin-top: 20px;
}