.template-items {
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
  border-radius: 5px;
  width: 98%;
  margin-bottom: 2%;
  margin-left: 5px;
}

.template-edit-and-delete {
  border: 1px solid transparent;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.template-save,
.create-new-template {
  margin-left: 5px;
}
.add-new-template {
  display: flex;
 
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}
.add-new-template-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
}

