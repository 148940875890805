.chart_container_header {
    /* display: flex;
    justify-content: flex-end;
    align-items: center; */
    border-radius: 5px;
    /* border-bottom: 1px solid #ccc; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px; */
    /* margin-bottom: 5px; */
    /* padding: 4px 8px; */
    position: absolute;
    z-index: 100;
}

.chart_container_chart {
    border-radius: 5px;
    border: 1px solid transparent;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px; */
}