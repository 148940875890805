* {
  outline: none;
  text-decoration: none;
}

.c-sidebar {
  background-color: rgb(16, 36, 71);
  border: 1px solid transparent;
  width: 5%;
  height: 100%;
  left: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  top: 0px;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.c-sidebar-logo {
  margin-top: 59%;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10%;
  width: 100%;
  height: 5%;
}

.c-sidebar-logo a {
  border: none;
  text-decoration: none;
  color: #fff;
}

.c-sidebar-logo a:hover {
  color: #fff;
}
.c-sidebar-logo .c-sidebar-links .active {
  background: #ffffff;
  color: rgb(16, 36, 71);
}

.c-sidebar-links {
  height: 95%;
  width: 100%;
  padding: 20%;
  overflow: auto;
}

.c-sidebar-links span > a {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.c-sidebar-links svg {
  margin-bottom: 50%;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  width: 40%;
  height: 20%;
  padding: 20%;
}

.c-sidebar-svg:hover {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #fff;
}
.c-sidebar-svg.active-icon {
  background-color: #fff; 
  color: rgb(16, 36, 71);
}
