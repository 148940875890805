.nerText {
  border: 2px double transparent;
  border-radius: 5px;
  padding: 5px;
  line-height: 35px;
  height: auto;
  font-size: 12px;
  color: rgb(16, 36, 71);
}

.ner_higilitedText {
  /* display: inline-block !important; */
  background-size: 8px 8px !important;
  background-position: right 5px top 5px !important;
  padding: 2px 5px;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer !important;
  color: rgb(16, 36, 71);
  font-size: 12px;
  font-weight: 500;
  height: 28px;
  margin: 0px 2px;
}

.ner_higilitedText-tag {
  margin: 0px;
  width: auto;
  height: 8px;
  background-color: aliceblue;
  color: rgb(16, 16, 17);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 4px;
  font-size: 8px;
  font-weight: 700;
  padding: 1px 4px 1px 4px;
  cursor: pointer;
}
