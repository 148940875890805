.multiple-files-dropdown {
  padding: 0px 6px;
  font-size: 12px;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  background-color: rgb(16, 36, 71);
  color: #dddddd;
  width: 130px;
  height: 25px;
  outline: none;
  margin-bottom: 8px;
}

.multiple-files-dropdown option {
  color: rgb(16, 36, 71);
  background-color: #fff;
  font-size: 12px;
  outline: none;
  padding: 5px 0px !important;
}