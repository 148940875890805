.breadcrum span {
  margin-left: 5px;
  margin-top: 3px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  color: #102447
}

.breadcrum_link {
  text-decoration: none;
  color: #102447
}

.breadcrum_link_svg {
  color: #102447;
  font-size: 13px
}