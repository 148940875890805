.history {
  padding: 10px 0px;
  height: calc(100vh - 100px);
  margin: 0 1%;
}

.history_table {
  text-align: center;

  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important;
}

.history_table .file_name,
.history_table .llm_type {
  max-width: 250px;
}

.history_table td {
  font-size: 13px;
  border: unset;
}
.history_table tr {
  border: 1px solid #dadada;
  vertical-align: middle;
}
.historyPreview .modal-dialog {
  max-width: 95% !important;
}

.historyPreview .modal-body {
  height: calc(100vh - 300px);
}

.bs-history {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bs-history .bs-history-pdf {
  width: 43%;
}

.bs-history-table {
  width: 55%;
  overflow: auto;
}

.bs-history-pdfInfo {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 8px;
}
.table-container {
  border: 1px solid #dcdcdc;
  padding: 5px;
  border-radius: 5px;
}

.wait_time {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wait_time > .spinner-grow {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.cre-processing-card-status-msg .badge {
  width: 90px;
  text-align: center;
}
.cre-processing-card-status {
  justify-content: center;
}
.table>:not(caption)>*>* {
  padding: 6px !important;
}