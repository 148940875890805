.itemsbox .item,
.selected-module-heading {
  font-weight: 600;
}
.module-selection-body-pannel {
  box-shadow: rgba(0, 0, 0, 0.24) 0 0 2px;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 10px auto;
  position: relative;
}
.fixedcls {
  position: absolute;
  top: 10px;
  right: 10px;
}
.homebtn,
.savebtn {
  border: transparent;
}
.Btns {
  gap: 10px;
  position: fixed;
  bottom: 50px;
  right: 25px;
  z-index: 100;
}
.homebtn {
  text-decoration: none;
}
.itemsbox {
  display: flex;
  justify-content: space-between;
}
.moduleselection-body .item {
  width: 230px;
}
.selected-value {
  background-color: rgba(var(--bs-primary-rgb));
  color: #fff;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 12px;
}
.selected-modules {
  padding: 0 16px;
  margin-top: -3px;
  min-width: 430px;
  border-left: 1px solid #d3d3d3;
}
.itemsboxes {
  display: flex;
  flex-wrap: wrap;
}
.selected-module-values {
  display: flex;
  flex-wrap: wrap;
  background-color: #e6e6e6;
  padding: 2px 10px 15px;
  border-radius: 10px;
}
.selected-module-value {
  margin: 10px 10px 0 0;
}
.card-title {
  font-size: 20px;
}
.no-modules-heading {
  font-size: 14px;
  padding: 10px 5px;
}
.card-title button {
  font-size: 16px;
  font-weight: 600;
}
.itemsbox .item {
  font-size: 14px;
  color: #525252;
}


