.table-bootstrap thead th {
  background: rgb(242, 249, 253);
}

.table-bootstrap {
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  font-size: 12px;
}
.table-bootstrap tr {
  height: 40px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
}

.outer-border {
  border: 1px solid #dadada;
}

.outer-border th,
.outer-border td {
  border: none;
}

.outer-border thead tr {
  /* border: 1px solid #dadada; */
  border: none !important;
}
.status-active {
  background-color: #cfffcf; /* Light green */
  color: rgb(0, 111, 0); /* Dark green text */
  font-weight: bold;
}

.status-inactive {
  background-color: #ffffde; /* Light yellow */
  color: #929210; /* Darker text color */
  font-weight: bold;
}

.status-pending {
  background-color: #eeeeee; /* Light gray */
  color: #333; /* Darker text color */
  font-weight: bold;
}
