.signin_form_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}

.signin_form_input {
    border: 1px solid transparent;
    margin-bottom: 20px
}

.signin_form_btn_cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.signin_form_btn_redirect {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    font-size: 12px;
    color: #8a8787
}

.signin_form_btn_cont>button {
    margin-top: 5px
}

.signin_form_forget_pass {
    color: #8a8787;
    margin-top: 8px;
    font-size: 12px
}

.signin_form_forget_pass:hover,
.signin_form_req_demo:hover {
    color: rgba(0, 0, 255, .678)
}

.signin_form_show_pass {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.signin_form_error {
    margin-top: 6px;
    font-size: 12px;
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    padding: 5px;
    text-align: center
}