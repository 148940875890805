.bsEditModal .modal-dialog {
    max-width: 600px !important;
  }
  
  .bsEditModal .modal-header {
    padding: 15px 25px;
    border-bottom: 1px solid #ccc !important;
  }
  
  .bsEditModal .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .bsEditModal .modal-body {
    padding: 20px 25px;
    height: calc(100vh - 200px);
    overflow: auto;
    border-bottom: 10px solid white;
  }
  
  .bsEditModal .modal-footer {
    padding: 15px 25px !important;
    border-top: 1px solid #ccc !important;
  }
  
  .bsEditModalForm .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .bsEditModalForm .editField {
    width: 47%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .bsEditModal .editField label, .bsEditModalForm .editField label {
    font-size: 13px;
    margin-bottom: 2px;
    font-weight: 500;
    color: #414040;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  
  .bsEditModal .editField input, .bsEditModalForm .editField span {
    padding: 7px 10px;
    border-radius: 4px;
    border: 1px solid #0f0e0e4a;
    font-size: 14px;
  }

  .editAccDetailsModal .modal-body {
    height: unset;
  }

  .viewTransactionModal .modal-dialog {
    max-width: 90% !important;
  }

  .viewTransactionModal .Extraction {
    height: unset;
  }

  .viewTransactionModal .Extraction-tables {
    height: unset;
  }

  .add-column {
    position: absolute;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
    width: 200px;
    z-index: 100;
  }

  .add-column .header {
    font-size: 12px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    font-weight: 600;
  }

  .add-column .body input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%;
  }

  .add-column .footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ccc;
    margin-top: 6px !important;
  }

  .add-column .footer button {
    width: auto;
    font-size: 10px;
    padding: 4px 10px;
    height: unset;
    margin-right: 10px;
    font-weight: 600;
  }